.experience__container {
    display: grid;;
    gap: 2rem;
    grid-template-columns: 1fr 1fr;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.experience__container > div:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}
.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color:var(--color-primary)
}

.experience__content {
    display: grid;;
    row-gap: 2rem;
    grid-template-columns: 1fr 1fr;
}

.experience__details{
 display: flex;
 gap:1rem  
}

.experience__details-icon{
    margin-top: 6px;
    color: var(--color-primary)
}

 /*-----------------tablettes-----------------------*/
 @media screen and  (max-width: 1024px) {
    .experience__container {
        grid-template-columns: 1fr;
        width: var(--container-width-md);
    }
    
    .experience__container > div {
        padding: 2rem;
    }
    .experience__content{
        padding: 1rem;
    }
  }
  /*-----------------small devices-----------------------*/
  @media screen and (max-width: 600px) {
    .experience__container {
        gap: 1rem;
        width: var(--container-width-sm);
    }
    .experience__container > div {
        padding: rem;
    }
  }
