footer{
    background: #030714;
    padding:3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 1rem;
}

footer a{
    font-size: 1.5rem;
    color: var(--color-white)
}

.footer__logo{
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}
.footer__img{
    width: 20%;
    height: 20%;
    margin: auto;

}

.permalinks{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin:0 auto 3rem;
}

.footer__socials{
    display:flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

.footer__socials a {
    background: var(--color-primary);
    color: var(--color-white);
    padding: 0.8rem;
    border-radius: 0.7rem;
    display:flex;
    border: 1px solid transparent
}
.footer__socials a:hover{
    background: transparent;
    color: var(--color-bg);
    border-color: var(--color-bg)

}

.footer__copyright{
    margin-bottom: 4rem;
    color:var(--color-bg)
}