.about__container {
    display: grid;
    grid-template: max-content;
    gap: 3%;
}

.about__me {
    width: 100%;
    border-radius: 2rem;
    background: -o-linear-gradient(
        45deg,
        transparent,
        var(--color-primary),
    );
    display: flex;
    justify-content: space-between;
    gap: 5%
}

.about__me-image{
    width: 50%;
    border-radius: 1rem 1rem 1rem 1rem ;
    overflow: hidden;
    transition: var(--transition);
}

.image-about {
    border-radius: 1rem !important;
}

.about__me-image:hover{
    transform: rotate(5deg);
}

.about__cards {
    height: 100%;
    display: grid;
    width: 144%;
    grid-template-columns: repeat(2,1fr);
    gap: 1.5rem;
}

.about__card {
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding:2rem;
    text-align: center;
}

.about__card:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;

}

.about__icons{
    color: var(--color-primary);
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.about__card h5 {
    font-size: 0.95rem;
}

.about__card small {
    font-size: 0.7rem;
    color: var(--color-light);
}

.about__content p {
    margin: 2rem;
    color: var(--color-light)
}

.btn-talk {
    margin: 2rem;
    display: flex;
    justify-content: center;
}

 /*-----------------tablettes-----------------------*/
 @media screen and  (max-width: 1024px) {
    .about__container{
        width: var(--container-width-md);
        grid-template-columns: 1fr;
        gap: 10;
    }
    .about__me-image{
        margin-bottom: 2rem;
    }
    .about__cards {
        width: 100%;
        grid-template-columns: repeat(1,1fr);
    }
    .about__me{
        display: block;
        margin: 2rem auto 0;;
    }
    .about__content p {
        margin: 1rem 0 1.5rem ;
    }
    .about__me-image{
        width: 100%;
    }
  }
  /*-----------------small devices-----------------------*/
  @media screen and  (max-width: 600px) {
    .about__container{
        width: var(--container-width-sm);
    }
    .about__me{
        display: block;
    }
    .about__me-image{
        margin-bottom: 2rem;
    }
    .about__content {
        text-align: center;
    }
  }
